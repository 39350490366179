<template>
  <div class="container w-sm-100 w-xs-100 px-5" id="recipient-wrapper">
    <div class="row">
      <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
        <h3 class="mt-2 mb-3">Who are you sending the money to? </h3>

        <div class="row">
          <div class="btn-choice-wrapper p-2">
            <button id="send-to-myself-btn" class="btn-choice-lg p-2 flex-col"
                    @click="sendToMyselfHandler()" :class="{shadow: sendToMyself}">
              Myself
            </button>
            <button id="send-to-someone-btn" class="btn-choice-lg p-2 flex-col"
                    @click="sendToSomeoneElseHandler()" :class="{shadow: sendToSomeoneElse}">
              Someone else
            </button>
          </div>
        </div>
        <!--SEND TO MYSELF-->
        <div v-show="sendToMyself">
          <div v-if="selfBankDetails.length > 0">
            <h4 class="text-center mt-1 mb-1">Select which banks to receive the payment</h4>
            <div class="btn-choice-wrapper p-2" v-for="(item,index) in selfBankDetails" :key="index">
              <div class="btn-choice-lg p-2 flex-col pointer" @click="selectRecipient(item)"
                   :class="[selectedRecipient && selectedRecipient.receiver_key && selectedRecipient.receiver_key === item.receiver_key ? 'shadow': '']">
                <div><strong>Account Holder Name</strong> : {{ item.acct_holder_name }}</div>
                <div><strong>Bank Name</strong> : {{ item.bank_name }}</div>
                <div><strong>Bank Account Number</strong> : {{ item.bank_acct_num }}</div>
                <div><strong>Bank Account Currency</strong> : {{ item.bank_acct_ccy }}</div>
              </div>
            </div>

            <div v-if="!addSelfBankAccount">
              <button class="btn btn-block mt-2 mb-5" @click="toggleFormForAddSelfBankAccount()"> + Add
                another bank account
              </button>
            </div>
            <div v-else>
              <div class="recipient-detail-form">
                <h4 class="mt-3">Enter your {{ receiveCurrency }} bank details</h4>
                <hr>
                <div class="row">
                  <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                    <label for="bank-account-holder-name-add-new" class="control-label">Bank account
                      holder
                      name </label>
                    <div class="input-group">
                      <input type="text" id="bank-account-holder-name-add-new"
                             name="bank-account-holder-name-myself" class="form-control"
                             v-model="newSelfBankAccountHolderName">
                    </div>
                    <small v-if="validationNewSelfBankAccountHolderNameError" class="text-error">Please
                      enter the bank
                      account
                      holder name</small>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                    <label for="bank-name-add-new" class="control-label">Bank name</label>
                    <div class="input-group">
                      <input type="text" id="bank-name-add-new" name="bank-name-myself"
                             class="form-control"
                             v-model="newSelfBankName">
                    </div>
                    <small v-if="validationNewSelfBankNameError" class="text-error">Please enter
                      your bank name</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6  mb-3">
                    <label for="bank-account-number-add-new" class="control-label">Bank account
                      number /
                      IBAN </label>
                    <div class="input-group">
                      <input type="text" id="bank-account-number-add-new"
                             name="bank-account-number-myself"
                             class="form-control"
                             v-model="newSelfBankAccountNumber">
                    </div>
                    <small v-if="validationNewSelfBankAccountNumberError" class="text-error">Please
                      enter your bank
                      account
                      number</small>
                  </div>
                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6  mb-3">
                    <label for="bank-account-currency-add-new" class="control-label">Bank account
                      currency</label>
                    <div class="input-group">
                      <!--                                            <input type="text" id="bank-account-currency-add-new"-->
                      <!--                                                   name="bank-account-currency-add-new"-->
                      <!--                                                   class="form-control"-->
                      <!--                                                   v-model="newSelfBankAccountCurrency">-->
                      <v-select :options="receiveCurrencyList"
                                :clearable="false"
                                label="currencyCode"
                                id="bank-account-currency-add-new"
                                name="bank-account-currency-add-new"
                                class="w-100"
                                v-model="newSelfBankAccountCurrency"
                                :reduce="item => item.currencyCode">
                        <template v-slot:option="item">
                          {{ item.currencyCode }} ({{ item.currencyName }})
                        </template>
                      </v-select>
                    </div>
                    <small v-if="validationNewSelfBankAccountCurrencyError" class="text-error">Please
                      enter your bank
                      account
                      currency</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                    <label for="bank-country-add-new" class="control-label"> Country</label>
                    <div class="input-group">
                      <!--                                            <select id="bank-country-add-new" name="bank-country-add-new" class=""-->
                      <!--                                                    v-if="countryList" v-model="newSelfBankCountry">-->
                      <!--                                                <option v-for="item in countryList" :value="item.countryCode"-->
                      <!--                                                        :key="item.name">-->
                      <!--                                                    {{item.name}} ({{item.countryCode}})-->
                      <!--                                                </option>-->
                      <!--                                            </select>-->
                      <v-select :options="countryList"
                                :clearable="false"
                                id="bank-country-add-new"
                                label="name"
                                class="w-100"
                                v-model="newSelfBankCountry"
                                :reduce="item => item.countryCode">
                        <template v-slot:option="item">
                          <country-flag :country="item.countryCode.toLowerCase()"
                                        size="small"></country-flag>
                          {{ item.name }}
                        </template>
                      </v-select>
                    </div>
                    <small v-if="validationNewSelfBankCountryError" class="text-error">Please select
                      the recipient
                      country</small>
                  </div>
                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6  mb-3">
                    <label for="swift-code-add-new" class="control-label" v-if="newSelfBankCountry === 'US'">ABA routing
                      number</label>
                    <label for="swift-code-add-new" class="control-label" v-else>SWIFT code</label>
                    <div class="input-group">
                      <input type="text" id="swift-code-add-new" name="swift-code-myself"
                             class="form-control"
                             v-model="newSelfBankSWIFTCode">
                    </div>
                    <small v-if="validationNewSelfBankSWIFTCodeError && newSelfBankCountry === 'US'" class="text-error">Please
                      enter ABA routing number</small>
                    <small v-else-if="validationNewSelfBankSWIFTCodeError && newSelfBankCountry !== 'US'"
                           class="text-error">Please
                      enter SWIFT
                      Code</small>
                  </div>

                </div>
                <div class="row mb-2">
                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6">
                    <button class="btn btn-block mt-2 btn-danger"
                            @click="closeFormForAddSelfBankAccount()">Cancel
                    </button>
                  </div>
                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6">
                    <button class="btn btn-block mt-2 btn-primary"
                            @click="submitNewSelfBankAccount()">Add
                    </button>
                  </div>
                </div>
                <br>
              </div>
            </div>
          </div>

          <div class="recipient-detail-form" v-else>
            <h4 class="mt-3">Enter your {{ receiveCurrency }} bank details</h4>
            <hr>
            <div class="row">
              <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                <label for="bank-account-holder-name-myself" class="control-label">Bank account holder
                  name </label>
                <div class="input-group">
                  <input type="text" id="bank-account-holder-name-myself"
                         name="bank-account-holder-name-myself" class="form-control"
                         v-model="selfBankAccountHolderName">
                </div>
                <small v-if="validationSelfBankAccountHolderNameError" class="text-error">Please enter
                  the bank account
                  holder name</small>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                <label for="bank-name-myself" class="control-label">Bank name</label>
                <div class="input-group">
                  <input type="text" id="bank-name-myself" name="bank-name-myself"
                         class="form-control"
                         v-model="selfBankName">
                </div>
                <small v-if="validationSelfBankNameError" class="text-error">Please enter your bank
                  name</small>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                <label for="bank-account-number-myself" class="control-label">Bank account number /
                  IBAN </label>
                <div class="input-group">
                  <input type="text" id="bank-account-number-myself"
                         name="bank-account-number-myself"
                         class="form-control"
                         v-model="selfBankAccountNumber">
                </div>
                <small v-if="validationSelfBankAccountNumberError" class="text-error">Please enter your
                  bank account
                  number</small>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                <label for="bank-account-currency-myself" class="control-label">Bank account
                  currency</label>
                <div class="input-group">
                  <!--                                    <input type="text" id="bank-account-currency-myself"-->
                  <!--                                           name="bank-account-currency-myself"-->
                  <!--                                           class="form-control"-->
                  <!--                                           v-model="selfBankAccountCurrency">-->
                  <v-select :options="receiveCurrencyList"
                            :clearable="false"
                            label="currencyCode"
                            id="bank-account-currency-myself"
                            name="bank-account-currency-myself"
                            class="w-100"
                            v-model="selfBankAccountCurrency"
                            :reduce="item => item.currencyCode">
                    <template v-slot:option="item">
                      {{ item.currencyCode }} ({{ item.currencyName }})
                    </template>
                  </v-select>
                </div>
                <small v-if="validationSelfBankAccountCurrencyError" class="text-error">Please enter
                  your bank account
                  currency</small>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                <label for="bank-country-myself" class="control-label">Bank Country</label>
                <div class="input-group">
                  <!--                                    <select id="bank-country-myself" name="bank-country-myself" class=""-->
                  <!--                                            v-if="countryList" v-model="selfBankCountry">-->
                  <!--                                        <option v-for="item in countryList" :value="item.countryCode" :key="item.name">-->
                  <!--                                            {{item.name}} ({{item.countryCode}})-->
                  <!--                                        </option>-->
                  <!--                                    </select>-->
                  <v-select :options="countryList"
                            :clearable="false"
                            id="bank-country-myself"
                            label="name"
                            class="w-100"
                            v-model="selfBankCountry"
                            :reduce="item => item.countryCode">
                    <template v-slot:option="item">
                      <country-flag :country="item.countryCode.toLowerCase()"
                                    size="small"></country-flag>
                      {{ item.name }}
                    </template>
                  </v-select>
                </div>
                <small v-if="validationSelfBankCountryError" class="text-error">Please enter the bank
                  country
                </small>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                <label for="swift-code-myself" class="control-label" v-if="selfBankCountry === 'US'">ABA routing
                  number</label>
                <label for="swift-code-myself" class="control-label" v-else>SWIFT code</label>
                <div class="input-group">
                  <input type="text" id="swift-code-myself" name="swift-code-myself"
                         class="form-control"
                         v-model="selfBankSWIFTCode">
                </div>
                <small v-if="validationSelfBankSWIFTCodeError && selfBankCountry === 'US'" class="text-error">Please
                  enter ABA routing number</small>
                <small v-else-if="validationSelfBankSWIFTCodeError && selfBankCountry !== 'US' " class="text-error">Please
                  enter SWIFT
                  Code</small>
              </div>

            </div>
            <br>
            <button class="btn btn-block btn-primary" @click="submitRecipientDetails()"
                    v-if="selfBankDetails.length === 0">Continue
            </button>

          </div>
        </div>

        <!--SEND TO ANOTHER-->
        <div v-show="sendToSomeoneElse">
          <div v-if="anotherBankDetails.length > 0">
            <h4 class="text-center mt-1 mb-1">Select which banks to receive the payment</h4>
            <div class="btn-choice-wrapper p-2" v-for="(item,index) in anotherBankDetails" :key="index">
              <div class="btn-choice-lg p-2 flex-col pointer" @click="selectRecipient(item)"
                   :class="[selectedRecipient && selectedRecipient.receiver_key && selectedRecipient.receiver_key === item.receiver_key ? 'shadow': '']">
                <div><strong>Recipient Name</strong> : {{ item.acct_holder_name }}</div>
                <div><strong>Bank Name</strong> : {{ item.bank_name }}</div>
                <div><strong>Bank Account Number</strong> : {{ item.bank_acct_num }}</div>
                <div><strong>Bank Account Currency</strong> : {{ item.bank_acct_ccy }}</div>
              </div>
            </div>

            <div v-if="!addAnotherBankAccount">
              <button class="btn btn-block mt-2 mb-5"
                      @click="toggleFormForAddAnotherRecipientBankDetails()"> + Add
                another recipient
              </button>

            </div>
            <div v-else>
              <div class="recipient-detail-form">
                <h4 class="mt-3">Recipient's Detail</h4>
                <hr>
                <div class="row">
                  <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                    <label for="recipient-full-name-new" class="control-label"> Full name</label>
                    <div class="input-group">
                      <input type="text" id="recipient-full-name-new"
                             name="recipient-full-name-new"
                             class="form-control" v-model="newRecipientFullName">
                    </div>
                    <small v-if="validationNewRecipientFullNameError" class="text-error">Please
                      enter recipient full
                      name</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                    <label for="recipient-address-new" class="control-label"> Address</label>
                    <div class="input-group">
                      <input type="text" id="recipient-address-new" name="recipient-address-new"
                             class="form-control" v-model="newRecipientAddress">
                    </div>
                    <small v-if="validationNewRecipientAddressError" class="text-error">Please enter
                      the recipient
                      address </small>
                  </div>
                </div>


                <!--                                <div class="row">-->
                <!--                                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">-->
                <!--                                        <label for="recipient-city-new" class="control-label">City</label>-->
                <!--                                        <div class="input-group">-->
                <!--                                            <input type="text" id="recipient-city-new" name="recipient-city-new"-->
                <!--                                                   class="form-control" v-model="newRecipientCity">-->
                <!--                                        </div>-->
                <!--                                        <small v-if="validationNewRecipientCityError" class="text-error">Please enter-->
                <!--                                            the recipient-->
                <!--                                            city </small>-->
                <!--                                    </div>-->
                <!--                                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">-->
                <!--                                    <label for="recipient-city-new" class="control-label">State/Province</label>-->
                <!--                                    <div class="input-group">-->
                <!--                                      <input type="text" id="recipient-state-new" name="recipient-city-new"-->
                <!--                                             class="form-control" v-model="newRecipientState">-->
                <!--                                    </div>-->
                <!--                                    &lt;!&ndash;                                        <small v-if="validationNewRecipientStateError" class="text-error">Please enter&ndash;&gt;-->
                <!--                                    &lt;!&ndash;                                            the recipient&ndash;&gt;-->
                <!--                                    &lt;!&ndash;                                            state </small>&ndash;&gt;-->
                <!--                                  </div>-->

                <!--                                </div>-->

                <div class="row">
                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                    <label for="recipient-postal-code-new" class="control-label">Postal Code</label>
                    <div class="input-group">
                      <input type="text" id="recipient-postal-code-new" name="recipient-postal-code-new"
                             class="form-control" v-model="newRecipientPostalCode">
                    </div>
                    <small v-if="validationNewRecipientPostalCodeError" class="text-error">Please
                      enter the recipient
                      postal code </small>
                  </div>
                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                    <label for="recipient-country-new" class="control-label"> Country</label>
                    <div class="input-group">
                      <!--                                            <select id="recipient-country-new" name="recipient-country-new" class="">-->
                      <!--                                                <option v-for="item in countryList" :value="item.countryCode"-->
                      <!--                                                        :key="item.name">-->
                      <!--                                                    {{item.name}} ({{item.countryCode}})-->
                      <!--                                                </option>-->
                      <!--                                            </select>-->
                      <v-select :options="countryList"
                                :clearable="false"
                                id="recipient-country-new"
                                label="name"
                                class="w-100"
                                v-model="newRecipientCountry"
                                :reduce="item => item.countryCode">
                        <template v-slot:option="item">
                          <country-flag :country="item.countryCode.toLowerCase()"
                                        size="small"></country-flag>
                          {{ item.name }}
                        </template>
                      </v-select>
                    </div>
                    <small v-if="validationNewRecipientCountryError" class="text-error">Please
                      select the recipient
                      country</small>
                  </div>

                </div>
                <!--                        <div class="row">-->
                <!--                            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">-->
                <!--                                <label for="recipient-address-unknown" class="control-label"> Address & postal-->
                <!--                                    code</label>-->
                <!--                                <div class="input-group">-->
                <!--                                    <input type="text" id="recipient-address-unknown" name="recipient-address-unknown"-->
                <!--                                           class="form-control" v-model="recipientAddress">-->
                <!--                                </div>-->
                <!--                                <small v-if="validationRecipientAddressError">Please enter the recipient-->
                <!--                                    address </small>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                                <div class="row">-->
                <!--                                    <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">-->
                <!--                                        <label for="recipient-email-new" class="control-label">Recipient email-->
                <!--                                            &lt;!&ndash;                                    <span&ndash;&gt;-->
                <!--                                            &lt;!&ndash;                                    v-if="recipientBankDetailsKnown">(optional)</span><span&ndash;&gt;-->
                <!--                                            &lt;!&ndash;                                    v-else>(required)</span>&ndash;&gt;-->
                <!--                                        </label>-->
                <!--                                        <div class="input-group">-->
                <!--                                            <input type="email" id="recipient-email-new" name="recipient-email-new"-->
                <!--                                                   class="form-control" v-model="newRecipientEmail">-->
                <!--                                        </div>-->
                <!--                                        <small v-if="validationNewRecipientEmailError" class="text-error">Please enter-->
                <!--                                            a valid recipient-->
                <!--                                            email</small>-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <!--                        <div class="row">-->
                <!--                            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">-->
                <!--                                <div class="form-group mt-2">-->
                <!--                                    <div class="checkbox">-->
                <!--                                        <label for="bank-details-known">-->
                <!--                                            <input class="" type="checkbox" id="bank-details-known"-->
                <!--                                                   name="bank-details-known22222" v-model="recipientBankDetailsKnown">-->
                <!--                                            I know their bank details-->
                <!--                                        </label>-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <div v-show="recipientBankDetailsKnown">
                  <h4 class="mt-3">Enter their {{ receiveCurrency }} Bank Details</h4>
                  <hr>
                  <div class="row">
                    <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                      <label for="bank-account-holder-name-new" class="control-label">Bank Account
                        Holder
                        Name </label>
                      <div class="input-group">
                        <input type="text" id="bank-account-holder-name-new"
                               name="bank-account-holder-name-known" class="form-control"
                               v-model="newRecipientBankAccountHolderName">
                      </div>
                      <small v-if="validationNewRecipientBankAccountHolderNameError"
                             class="text-error">Please enter
                        the bank
                        account holder name</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                      <label for="bank-name-new" class="control-label">Bank Name</label>
                      <div class="input-group">
                        <input type="text" id="bank-name-new" name="bank-name-new"
                               class="form-control" v-model="newRecipientBankName">
                      </div>
                      <small v-if="validationNewRecipientBankNameError" class="text-error">Please
                        enter the bank
                        name</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                      <label for="bank-account-number-new" class="control-label">Bank Account
                        Number /
                        IBAN </label>
                      <div class="input-group">
                        <input type="text" id="bank-account-number-new"
                               name="bank-account-number-new"
                               class="form-control" v-model="newRecipientBankAccountNumber">
                      </div>
                      <small v-if="validationNewRecipientBankAccountNumberError"
                             class="text-error">Please enter the
                        bank
                        account number</small>
                    </div>
                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                      <label for="bank-account-currency-new" class="control-label">Bank Account
                        Currency </label>
                      <div class="input-group">
                        <!--                                                <input type="text" id="bank-account-currency-new"-->
                        <!--                                                       name="bank-account-currency-new"-->
                        <!--                                                       class="form-control" v-model="newRecipientBankAccountCurrency">-->
                        <v-select :options="receiveCurrencyList"
                                  :clearable="false"
                                  label="currencyCode"
                                  id="bank-account-currency-new"
                                  name="bank-account-currency-new"
                                  class="w-100"
                                  v-model="newRecipientBankAccountCurrency"
                                  :reduce="item => item.currencyCode">
                          <template v-slot:option="item">
                            {{ item.currencyCode }} ({{ item.currencyName }})
                          </template>
                        </v-select>

                      </div>
                      <small v-if="validationNewRecipientBankAccountCurrencyError"
                             class="text-error">Please enter the
                        bank
                        account currency</small>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                      <label for="bank-country-new" class="control-label">Bank Country</label>
                      <div class="input-group ">
                        <!--                                                <select id="bank-country-new" name="bank-country-new" class=""-->
                        <!--                                                        v-if="countryList" v-model="newRecipientBankCountry">-->
                        <!--                                                    <option v-for="item in countryList" :value="item.countryCode"-->
                        <!--                                                            :key="item.name">-->
                        <!--                                                        {{item.name}} ({{item.countryCode}})-->
                        <!--                                                    </option>-->
                        <!--                                                </select>-->
                        <v-select :options="countryList"
                                  :clearable="false"
                                  id="bank-country-new"
                                  label="name"
                                  class="w-100"
                                  v-model="newRecipientBankCountry"
                                  :reduce="item => item.countryCode">
                          <template v-slot:option="item">
                            <country-flag :country="item.countryCode.toLowerCase()"
                                          size="small"></country-flag>
                            {{ item.name }}
                          </template>
                        </v-select>
                      </div>
                      <small v-if="validationNewRecipientBankCountryError" class="text-error">Please
                        select the bank
                        country</small>
                    </div>
                    <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                      <label for="swift-code-new" class="" v-if="newRecipientBankCountry === 'US'">ABA routing
                        number</label>
                      <label for="swift-code-new" class="" v-else>SWIFT Code</label>
                      <div class="input-group">
                        <input type="text" id="swift-code-new" name="swift-code-new"
                               class="form-control" v-model="newRecipientBankSWIFTCode">
                      </div>
                      <small v-if="validationNewRecipientBankSWIFTCodeError && newRecipientBankCountry === 'US'"
                             class="text-error">Please
                        enter ABA routing number</small>
                      <small v-else-if="validationNewRecipientBankSWIFTCodeError && newRecipientBankCountry !== 'US'"
                             class="text-error">Please
                        enter the
                        SWIFT
                        Code</small>
                    </div>

                  </div>
                </div>

                <!--                                <div v-show="!recipientBankDetailsKnown">-->
                <!--                                    <p>Please enter their email address in the required field above. We’ll send an email to-->
                <!--                                        request-->
                <!--                                        their bank details once you’ve paid for your transfer.</p>-->
                <!--                                    <p>Make sure they respond to our email or the money will be refunded to you.</p>-->
                <!--                                </div>-->

                <div class="row mb-3">
                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6">
                    <button class="btn btn-block mt-2 btn-danger"
                            @click="closeFormForAddAnotherRecipientBankDetails()">Cancel
                    </button>
                  </div>
                  <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6">
                    <button class="btn btn-block mt-2 btn-primary"
                            @click="submitNewAnotherRecipientBankAccount()">Add
                    </button>
                  </div>
                </div>
                <br>
              </div>
            </div>
          </div>
          <div class="recipient-detail-form" v-else>
            <h4 class="mt-3">Recipient's Detail</h4>
            <hr>
            <div class="row">
              <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                <label for="recipient-full-name-unknown" class="control-label"> Full name</label>
                <div class="input-group">
                  <input type="text" id="recipient-full-name-unknown"
                         name="recipient-full-name-unknown"
                         class="form-control" v-model="recipientFullName">
                </div>
                <small v-if="validationRecipientFullNameError" class="text-error">Please enter recipient
                  full
                  name</small>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                <label for="recipient-address-unknown" class="control-label"> Address</label>
                <div class="input-group">
                  <input type="text" id="recipient-address-unknown" name="recipient-address-unknown"
                         class="form-control" v-model="recipientAddress">
                </div>
                <small v-if="validationRecipientAddressError" class="text-error">Please enter the
                  recipient
                  address </small>
              </div>
            </div>


            <!--                        <div class="row">-->
            <!--                            <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">-->
            <!--                                <label for="recipient-city-unknown" class="control-label">City</label>-->
            <!--                                <div class="input-group">-->
            <!--                                    <input type="text" id="recipient-city-unknown" name="recipient-city-unknown"-->
            <!--                                           class="form-control" v-model="recipientCity">-->
            <!--                                </div>-->
            <!--                                <small v-if="validationRecipientCityError" class="text-error">Please enter the recipient-->
            <!--                                    city </small>-->
            <!--                            </div>-->
            <!--                          <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">-->
            <!--                            <label for="recipient-city-unknown" class="control-label">State/Province</label>-->
            <!--                            <div class="input-group">-->
            <!--                              <input type="text" id="recipient-state-unknown" name="recipient-city-unknown"-->
            <!--                                     class="form-control" v-model="recipientState">-->
            <!--                            </div>-->
            <!--                            &lt;!&ndash;                                <small v-if="validationRecipientStateError" class="text-error">Please enter the&ndash;&gt;-->
            <!--                            &lt;!&ndash;                                    recipient state </small>&ndash;&gt;-->
            <!--                          </div>-->

            <!--                        </div>-->

            <div class="row">
              <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                <label for="recipient-postal-code-unknown" class="control-label">Postal Code</label>
                <div class="input-group">
                  <input type="text" id="recipient-postal-code-unknown" name="recipient-postal-code-unknown"
                         class="form-control" v-model="recipientPostalCode">
                </div>
                <small v-if="validationRecipientPostalCodeError" class="text-error">Please enter the
                  recipient
                  postal code </small>
              </div>

              <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                <label for="recipient-country-unknown" class="control-label"> Country</label>
                <div class="input-group ">
                  <!--                                    <select id="recipient-country-unknown" name="recipient-country-unknown" class="">-->
                  <!--                                        <option v-for="item in countryList" :value="item.countryCode" :key="item.name">-->
                  <!--                                            {{item.name}} ({{item.countryCode}})-->
                  <!--                                        </option>-->
                  <!--                                    </select>-->
                  <v-select :options="countryList"
                            :clearable="false"
                            id="recipient-country-unknown"
                            label="name"
                            class="w-100"
                            v-model="recipientCountry"
                            :reduce="item => item.countryCode">
                    <template v-slot:option="item">
                      <country-flag :country="item.countryCode.toLowerCase()"
                                    size="small"></country-flag>
                      {{ item.name }}
                    </template>
                  </v-select>
                </div>
                <small v-if="validationRecipientCountryError" class="text-error">Please select the
                  recipient
                  country</small>
              </div>

            </div>
            <!--                        <div class="row">-->
            <!--                            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">-->
            <!--                                <label for="recipient-address-unknown" class="control-label"> Address & postal-->
            <!--                                    code</label>-->
            <!--                                <div class="input-group">-->
            <!--                                    <input type="text" id="recipient-address-unknown" name="recipient-address-unknown"-->
            <!--                                           class="form-control" v-model="recipientAddress">-->
            <!--                                </div>-->
            <!--                                <small v-if="validationRecipientAddressError">Please enter the recipient-->
            <!--                                    address </small>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="row">-->
            <!--                            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">-->
            <!--                                <label for="recipient-email-unknown" class="control-label">Recipient email-->
            <!--                                    &lt;!&ndash;                                    <span&ndash;&gt;-->
            <!--                                    &lt;!&ndash;                                    v-if="recipientBankDetailsKnown">(optional)</span><span&ndash;&gt;-->
            <!--                                    &lt;!&ndash;                                    v-else>(required)</span>&ndash;&gt;-->
            <!--                                </label>-->
            <!--                                <div class="input-group">-->
            <!--                                    <input type="email" id="recipient-email-unknown" name="recipient-email-unknown"-->
            <!--                                           class="form-control" v-model="recipientEmail">-->
            <!--                                </div>-->
            <!--                                <small v-if="validationRecipientEmailError" class="text-error">Please enter a valid-->
            <!--                                    recipient email</small>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="row">-->
            <!--                            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">-->
            <!--                                <div class="form-group mt-2">-->
            <!--                                    <div class="checkbox">-->
            <!--                                        <label for="bank-details-known">-->
            <!--                                            <input class="" type="checkbox" id="bank-details-known"-->
            <!--                                                   name="bank-details-known22222" v-model="recipientBankDetailsKnown">-->
            <!--                                            I know their bank details-->
            <!--                                        </label>-->
            <!--                                    </div>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <div v-show="recipientBankDetailsKnown">
              <h4 class="mt-3">Enter their {{ receiveCurrency }} Bank Details</h4>
              <hr>
              <div class="row">
                <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                  <label for="bank-account-holder-name-known" class="control-label">Bank Account
                    Holder
                    Name </label>
                  <div class="input-group">
                    <input type="text" id="bank-account-holder-name-known"
                           name="bank-account-holder-name-known" class="form-control"
                           v-model="recipientBankAccountHolderName">
                  </div>
                  <small v-if="validationRecipientBankAccountHolderNameError" class="text-error">Please
                    enter the bank
                    account holder name</small>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
                  <label for="bank-name-known" class="control-label">Bank Name</label>
                  <div class="input-group">
                    <input type="text" id="bank-name-known" name="bank-name-known"
                           class="form-control" v-model="recipientBankName">
                  </div>
                  <small v-if="validationRecipientBankNameError" class="text-error">Please enter the
                    bank name</small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                  <label for="bank-account-number-known" class="control-label">Bank Account Number /
                    IBAN </label>
                  <div class="input-group">
                    <input type="text" id="bank-account-number-known"
                           name="bank-account-number-known"
                           class="form-control" v-model="recipientBankAccountNumber">
                  </div>
                  <small v-if="validationRecipientBankAccountNumberError" class="text-error">Please
                    enter the bank
                    account number</small>
                </div>
                <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                  <label for="bank-account-currency-known" class="control-label">Bank Account Currency
                  </label>
                  <div class="input-group">
                    <!--                                        <input type="text" id="bank-account-currency-known"-->
                    <!--                                               name="bank-account-currency-known"-->
                    <!--                                               class="form-control" v-model="recipientBankAccountCurrency">-->
                    <v-select :options="receiveCurrencyList"
                              :clearable="false"
                              label="currencyCode"
                              id="bank-account-currency-known"
                              name="bank-account-currency-known"
                              class="w-100"
                              v-model="recipientBankAccountCurrency"
                              :reduce="item => item.currencyCode">
                      <template v-slot:option="item">
                        {{ item.currencyCode }} ({{ item.currencyName }})
                      </template>
                    </v-select>
                  </div>
                  <small v-if="validationRecipientBankAccountCurrencyError" class="text-error">Please
                    enter the bank
                    account currency</small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                  <label for="bank-country-known" class="control-label"> Country</label>
                  <div class="input-group ">
                    <!--                                        <select id="bank-country-known" name="bank-country-known" class=""-->
                    <!--                                                v-if="countryList" v-model="recipientBankCountry">-->
                    <!--                                            <option v-for="item in countryList" :value="item.countryCode"-->
                    <!--                                                    :key="item.name">-->
                    <!--                                                {{item.name}} ({{item.countryCode}})-->
                    <!--                                            </option>-->
                    <!--                                        </select>-->
                    <v-select :options="countryList"
                              :clearable="false"
                              id="bank-country-known"
                              label="name"
                              class="w-100"
                              v-model="recipientBankCountry"
                              :reduce="item => item.countryCode">
                      <template v-slot:option="item">
                        <country-flag :country="item.countryCode.toLowerCase()"
                                      size="small"></country-flag>
                        {{ item.name }}
                      </template>
                    </v-select>
                  </div>
                  <small v-if="validationRecipientBankCountryError" class="text-error">Please select
                    the bank
                    country</small>
                </div>
                <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
                  <label for="swift-code-known" class="" v-if="recipientBankCountry === 'US'">ABA routing number</label>
                  <label for="swift-code-known" class="" v-else>SWIFT Code</label>
                  <div class="input-group">
                    <input type="text" id="swift-code-known" name="swift-code-known"
                           class="form-control" v-model="recipientBankSWIFTCode">
                  </div>
                  <small v-if="validationRecipientBankSWIFTCodeError && recipientBankCountry === 'US'"
                         class="text-error">Please enter
                    the ABA routing number</small>
                  <small v-if="validationRecipientBankSWIFTCodeError && recipientBankCountry !== 'US'"
                         class="text-error">Please enter
                    the SWIFT
                    code</small>
                </div>
              </div>
            </div>

            <div v-show="!recipientBankDetailsKnown">
              <p>Please enter their email address in the required field above. We’ll send an email to
                request
                their bank details once you’ve paid for your transfer.</p>
              <p>Make sure they respond to our email or the money will be refunded to you.</p>
            </div>
            <br>

            <button class="btn btn-block btn-primary" @click="submitRecipientDetails()"
                    v-if="anotherBankDetails.length === 0">Continue
            </button>
          </div>
        </div>

        <p class="text-error text-center" v-if="validationErrorRecipient">Please select your recipient
          before continue with the transfer</p>
        <!--                <button class="btn btn-block btn-primary" @click="submitRecipientDetails()" v-if="selfBankDetails.length === 0 || anotherBankDetails.length === 0">Continue</button>-->

      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {countries} from 'countries-list';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import CountryFlag from 'vue-country-flag'


export default {
  name: "recipient-details",
  components: {
    CountryFlag,
    vSelect
  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",
      sendToMyself: true,
      sendToSomeoneElse: false,
      recipientBankDetailsKnown: true,

      // selfBankDetails: [],
      // anotherBankDetails: [],


      selectedRecipient: null,
      validationErrorRecipient: false,

      //self
      selfBankAccountHolderName: "",
      selfBankAccountNumber: "",
      selfBankName: "",
      selfBankSWIFTCode: "",
      selfBankCountry: "",
      selfBankAccountCurrency: "",

      validationSelfBankAccountHolderNameError: false,
      validationSelfBankAccountNumberError: false,
      validationSelfBankNameError: false,
      validationSelfBankSWIFTCodeError: false,
      validationSelfBankCountryError: false,
      validationSelfBankAccountCurrencyError: false,


      addSelfBankAccount: false,

      newSelfBankAccountHolderName: "",
      newSelfBankAccountNumber: "",
      newSelfBankName: "",
      newSelfBankSWIFTCode: "",
      newSelfBankCountry: "",
      newSelfBankAccountCurrency: "",

      validationNewSelfBankAccountHolderNameError: false,
      validationNewSelfBankAccountNumberError: false,
      validationNewSelfBankNameError: false,
      validationNewSelfBankSWIFTCodeError: false,
      validationNewSelfBankCountryError: false,
      validationNewSelfBankAccountCurrencyError: false,


      //another
      recipientFullName: "",
      recipientCountry: "",
      recipientCity: "",
      recipientAddress: "",
      recipientState: "",
      recipientPostalCode: "",
      recipientEmail: "",
      recipientBankAccountHolderName: "",
      recipientBankAccountNumber: "",
      recipientBankName: "",
      recipientBankSWIFTCode: "",
      recipientBankCountry: "",
      recipientBankAccountCurrency: "",

      validationRecipientFullNameError: false,
      validationRecipientCountryError: false,
      validationRecipientCityError: false,
      validationRecipientAddressError: false,
      validationRecipientStateError: false,
      validationRecipientPostalCodeError: false,
      validationRecipientEmailError: false,
      validationRecipientBankAccountHolderNameError: false,
      validationRecipientBankAccountNumberError: false,
      validationRecipientBankNameError: false,
      validationRecipientBankSWIFTCodeError: false,
      validationRecipientBankCountryError: false,
      validationRecipientBankAccountCurrencyError: false,

      addAnotherBankAccount: false,

      newRecipientFullName: "",
      newRecipientCountry: "",
      newRecipientCity: "",
      newRecipientAddress: "",
      newRecipientState: "",
      newRecipientPostalCode: "",
      newRecipientEmail: "",
      newRecipientBankAccountHolderName: "",
      newRecipientBankAccountNumber: "",
      newRecipientBankName: "",
      newRecipientBankSWIFTCode: "",
      newRecipientBankCountry: "",
      newRecipientBankAccountCurrency: "",

      validationNewRecipientFullNameError: false,
      validationNewRecipientCountryError: false,
      validationNewRecipientCityError: false,
      validationNewRecipientAddressError: false,
      validationNewRecipientStateError: false,
      validationNewRecipientPostalCodeError: false,
      validationNewRecipientEmailError: false,
      validationNewRecipientBankAccountHolderNameError: false,
      validationNewRecipientBankAccountNumberError: false,
      validationNewRecipientBankNameError: false,
      validationNewRecipientBankSWIFTCodeError: false,
      validationNewRecipientBankCountryError: false,
      validationNewRecipientBankAccountCurrencyError: false,

      debugConsole: false,

      receiveCurrencies: [{countryCode: "us", currencyCode: "USD", currencyName: "United States Dollar"},
        {countryCode: "eu", currencyCode: "EUR", currencyName: "Euro"},
        {countryCode: "gb", currencyCode: "GBP", currencyName: "British Pound"},
        {countryCode: "au", currencyCode: "AUD", currencyName: "Australia Dollar"},
        {countryCode: "sg", currencyCode: "SGD", currencyName: "Singapore Dollar"},
        {countryCode: "za", currencyCode: "ZAR", currencyName: "South Africa Rand"},
        //receiving currencies
        {countryCode: "ae", currencyCode: "AED", currencyName: "United Arab Emirates Dirham"},
        {countryCode: "bh", currencyCode: "BHD", currencyName: "Bahraini Dinar"},
        {countryCode: "bn", currencyCode: "BND", currencyName: "Bruneian Dollar"},
        {countryCode: "ca", currencyCode: "CAD", currencyName: "Canadian Dollar"},
        {countryCode: "ch", currencyCode: "CHF", currencyName: "Swiss Franc"},
        {countryCode: "cn", currencyCode: "CNY", currencyName: "Chinese Yuan"},
        {countryCode: "dk", currencyCode: "DKK", currencyName: "Danish Krone"},
        {countryCode: "eg", currencyCode: "EGP", currencyName: "Egyptian Pound"},
        {countryCode: "hk", currencyCode: "HKD", currencyName: "Hong Kong Dollar"},
        {countryCode: "hu", currencyCode: "HUF", currencyName: "Hungarian Forint"},
        {countryCode: "id", currencyCode: "IDR", currencyName: "Indonesian Rupiah"},
        {countryCode: "in", currencyCode: "INR", currencyName: "Indian Rupee"},
        {countryCode: "jp", currencyCode: "JPY", currencyName: "Japanese Yen"},
        {countryCode: "kr", currencyCode: "KRW", currencyName: "South Korean Won"},
        {countryCode: "kw", currencyCode: "KWD", currencyName: "Kuwaiti Dinar"},
        {countryCode: "lk", currencyCode: "LKR", currencyName: "Sri Lankan Rupee"},
        {countryCode: "mu", currencyCode: "MUR", currencyName: "Mauritian Rupee"},
        {countryCode: "mx", currencyCode: "MXN", currencyName: "Mexican Peso"},
        {countryCode: "my", currencyCode: "MYR", currencyName: "Malaysian Ringgit"},
        {countryCode: "no", currencyCode: "NOK", currencyName: "Norwegian Krone"},
        {countryCode: "nz", currencyCode: "NZD", currencyName: "New Zealand Dollar"},
        {countryCode: "ph", currencyCode: "PHP", currencyName: "Philippine Peso"},
        {countryCode: "pl", currencyCode: "PLN", currencyName: "Polish Zloty"},
        {countryCode: "qa", currencyCode: "QAR", currencyName: "Qatari Riyal"},
        // {countryCode: "ru", currencyCode: "RUB", currencyName: "Russian Ruble"},
        {countryCode: "sa", currencyCode: "SAR", currencyName: "Saudi Arabian Riyal"},
        {countryCode: "se", currencyCode: "SEK", currencyName: "Swedish Krona"},
        {countryCode: "th", currencyCode: "THB", currencyName: "Thai Baht"},
        {countryCode: "tr", currencyCode: "TRY", currencyName: "Turkish Lira"},
        {countryCode: "tw", currencyCode: "TWD", currencyName: "Taiwan New Dollar"},
        // {countryCode: "ng", currencyCode: "NGN", currencyName: "Nigerian Naira"},
        {countryCode: "br", currencyCode: "BRL", currencyName: "Brazilian Real"},
        {countryCode: "bd", currencyCode: "BDT", currencyName: "Bangladesh Taka"},
        {countryCode: "co", currencyCode: "COP", currencyName: "Colombian Peso"},
        {countryCode: "gh", currencyCode: "GHS", currencyName: "Ghanaian Cedi"},
        {countryCode: "ke", currencyCode: "KES", currencyName: "Kenyan Shilling"},
        {countryCode: "pe", currencyCode: "PEN", currencyName: "Peruvian Sol"},
        {countryCode: "tz", currencyCode: "TZS", currencyName: "Tanzanian Shilling"}],


      countryList: Object.entries(countries).map((item) => {
        return Object.assign(item[1], {countryCode: item[0]})
      }).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }).filter(item => ["AL", "BS", "BB", "BW", "KH", "IS", "JM", "MU", "MN", "MM", "NI", "PK", "PA", "SY", "UG", "YE", "ZW", "IR", "KP", "GS", "NG","RU","BY"].indexOf(item.countryCode) < 0),
    }
  },
  watch: {
    $route() {

    },
    addAnotherBankAccount(newVal, oldVal) {
      var vm = this;
      if (this.debugConsole) console.log("new Val", newVal);
      if (this.debugConsole) console.log("Old Val", oldVal);

    },
    addSelfBankAccount(newVal, oldVal) {
      var vm = this;
      if (this.debugConsole) console.log("new Val", newVal);
      if (this.debugConsole) console.log("Old Val", oldVal);

    }
  },
  computed: {
    ...mapGetters(["paymentDetailsGetter", "userDetailsGetter"]),


    receiveCurrency() {
      return this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.receiveCurrency;
    },

    selfBankDetails() {
      let selfBankDetails = this.userDetailsGetter && this.userDetailsGetter.recipients && this.userDetailsGetter.recipients.filter(el => el.receiver_type === "self" && el.acct_holder_name !== null);
      return selfBankDetails;
    },

    anotherBankDetails() {
      let someoneBankDetails = this.userDetailsGetter && this.userDetailsGetter.recipients && this.userDetailsGetter.recipients.filter(el => el.receiver_type === "another" && el.acct_holder_name !== null);
      return someoneBankDetails;
    },
    receiveCurrencyList() {
      let countries = this.receiveCurrencies;
      let sortedCountries = countries.sort((a, b) => (a.currencyCode > b.currencyCode) ? 1 : -1);
      return sortedCountries;
    }
  },
  metaInfo: {
    title: 'TransCrypt - Recipient Details | Send Money Faster with Cryptocurrency',
  },
  mounted() {
    var vm = this;
    if (this.debugConsole) console.debug("Recipient Details Mounted");

    //for stepper
    let target = "recipient-info";
    this.$store.commit('UPDATE_STEPPER', target);

    if (this.debugConsole) console.log("Self Bank Details", this.selfBankDetails);
    if (this.debugConsole) console.log("Another Bank details", this.anotherBankDetails);


    //for send to myself
    //check if there are existing bank details or not. If yes, show the existing bank details and show the Add bank details button.If not, just show the form immediately.

    // if(user bank details .length > 0)
    // {
    //     show the existing bank details and show add button to add new self bank details.
    // }else

    // the data format might change ('bankDetails should be array')
    this.selfBankAccountHolderName = "";
    this.selfBankAccountNumber = "";
    this.selfBankName = "";
    this.selfBankSWIFTCode = "";
    this.selfBankCountry = "";
    this.selfBankAccountCurrency = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && (this.paymentDetailsGetter.remittanceInfo.receiveCurrency || "");


    //for send to someone else
    //check if there are existing recipients or not. If not, show the existing recipient (the profile & bank details). If not, just show the form immediately.

    // if(recipients.details.length > 0)
    // {
    //     show the existing recipient and show the add button recipient and their bank details;
    // }
    // else
    this.recipientFullName = "";
    this.recipientCountry = "";
    this.recipientCity = "";
    this.recipientAddress = "";
    this.recipientEmail = "";
    this.recipientBankAccountHolderName = "";
    this.recipientBankAccountNumber = "";
    this.recipientBankName = "";
    this.recipientBankSWIFTCode = "";
    this.recipientBankCountry = "";
    this.recipientBankAccountCurrency = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && (this.paymentDetailsGetter.remittanceInfo.receiveCurrency || "");


    //prepopulate currency for self new bank account & another new bank account
    this.newRecipientBankAccountCurrency = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && (this.paymentDetailsGetter.remittanceInfo.receiveCurrency || "");
    this.newSelfBankAccountCurrency = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && (this.paymentDetailsGetter.remittanceInfo.receiveCurrency || "");

  },
  methods: {
    ...mapActions(["submitSelfBankDetails", "submitRecipientProfileAndBankDetails", "createRecipientProfile", "updateRecipientBank",
      "getReceivers", "getReviewTransaction", "linkTransactionWithReceiver"]),

    sendToMyselfHandler() {
      this.sendToMyself = true;
      this.sendToSomeoneElse = false;
      this.selectedRecipient = null;
    },
    sendToSomeoneElseHandler() {
      this.sendToMyself = false;
      this.sendToSomeoneElse = true;
      this.selectedRecipient = null;

    },

    selectRecipient(item) {
      // set loader
      this.$vs.loading({
          container: '#recipient-wrapper',
          scale: 0.8,
          color: 'success'
      });
      
      if (this.debugConsole) console.log("Item selected", item);
      this.selectedRecipient = item;
      if (this.debugConsole) console.log("Selected Recipient", this.selectedRecipient);

      let receiverKey = this.selectedRecipient && this.selectedRecipient.receiver_key;

      //only need to link and call the review transaction
      let payload = {};
      payload.receiverKey = receiverKey

      //Link receiver with transaction
      this.linkTransactionWithReceiver(payload).then(res => {
        if (this.debugConsole) console.log("link transaction with receiver with selected Self recipient result", res)

        let payload = {};
        payload.txReference = res.data && res.data.tx_ref_num;
        payload.receiverKey = receiverKey;

        //get the review transaction data
        this.getReviewTransaction(payload).then(res => {
          if (this.debugConsole) console.log("Get Review Transaction result with selected self recipient", res);

          // close loader and route push to review page
          this.$vs.loading.close('#recipient-wrapper > .con-vs-loading');
          this.$store.commit('UPDATE_STEPPER', 'review-info');

          this.$router.push('/transfer/review-details');
          this.getReceiversAfterSubmitNewBankDetails();
        }).catch(err => {
          if (this.debugConsole) console.log("Get Review Transaction error with selected self recipient", err);
        })

      }).catch(err => {
        if (this.debugConsole) console.log("Error link transaction with receiver", err.response);
        // close loader if error occur
        this.$vs.loading.close('#recipient-wrapper > .con-vs-loading');
      })


    },

    toggleFormForAddSelfBankAccount() {
      this.addSelfBankAccount = true;
    },

    closeFormForAddSelfBankAccount() {
      this.addSelfBankAccount = false;
    },

    toggleFormForAddAnotherRecipientBankDetails() {
      this.addAnotherBankAccount = true;
    },
    closeFormForAddAnotherRecipientBankDetails() {
      this.addAnotherBankAccount = false;
    },

    submitNewSelfBankAccount() {
      if (!this.newSelfBankAccountHolderName) {
        this.validationNewSelfBankAccountHolderNameError = true;
      } else {
        this.validationNewSelfBankAccountHolderNameError = false;
      }

      if (!this.newSelfBankAccountNumber) {
        this.validationNewSelfBankAccountNumberError = true;
      } else {
        this.validationNewSelfBankAccountNumberError = false;
      }

      if (!this.newSelfBankName) {
        this.validationNewSelfBankNameError = true;
      } else {
        this.validationNewSelfBankNameError = false;
      }

      if (!this.newSelfBankSWIFTCode) {
        this.validationNewSelfBankSWIFTCodeError = true;
      } else {
        this.validationNewSelfBankSWIFTCodeError = false;
      }

      if (!this.newSelfBankCountry) {
        this.validationNewSelfBankCountryError = true;
      } else {
        this.validationNewSelfBankCountryError = false;
      }

      if (!this.newSelfBankAccountCurrency) {
        this.validationNewSelfBankAccountCurrencyError = true;
      } else {
        this.validationNewSelfBankAccountCurrencyError = false;
      }

      if (this.validationNewSelfBankAccountHolderNameError ||
          this.validationNewSelfBankAccountNumberError ||
          this.validationNewSelfBankNameError ||
          this.validationNewSelfBankSWIFTCodeError || this.validationNewSelfBankCountryError || this.validationNewSelfBankAccountCurrencyError) {
        return;
      }

      // set loader
      this.$vs.loading({
          container: '#recipient-wrapper',
          scale: 0.8,
          color: 'success'
      });

      //send to API to create recipient
      let payload = {}
      payload.receiver_type = "self"
      payload.name = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderFirstAndMiddleName + " " + this.userDetailsGetter.profile.senderLastName);
      payload.country = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderCountry;
      payload.address_lines = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderAddress;
      payload.address_city = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderCity;
      // payload.address_state = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderProvince;
      payload.postal_code = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderPostalCode;
      payload.email = this.userDetailsGetter.email;

      if (this.debugConsole) console.log("Payload for sending to myself (new)", payload);

      this.createRecipientProfile(payload).then(res => {
        if (this.debugConsole) console.log("create recipient profile result in transfer recipient details (self) comp", res);

        let receiverKey = res.data && res.data.receiver_key;

        //get the receiver key to update the bank
        let payload = {}
        payload.receiverKey = receiverKey;
        payload.bank = {}
        payload.bank.acct_name = this.newSelfBankAccountHolderName;
        payload.bank.acct_num = this.newSelfBankAccountNumber;
        payload.bank.bank_name = this.newSelfBankName;
        payload.bank.bank_swift = this.newSelfBankSWIFTCode;
        payload.bank.bank_country = this.newSelfBankCountry;
        payload.bank.acct_ccy = this.newSelfBankAccountCurrency;

        if (this.debugConsole) console.log("Update Recipient bank payload (new)", payload);

        this.updateRecipientBank(payload).then(res => {
          if (this.debugConsole) console.log("Update recipient bank result in transfer recipient details (self) comp (new)", res)
          // this.closeFormForAddSelfBankAccount();
          this.getReceiversAfterSubmitNewBankDetails();

          //Link and call the review transaction
          let payload = {};
          payload.receiverKey = receiverKey;

          if (this.debugConsole) console.log("Link transaction for self bank details (new)");

          //Link receiver with transaction
          this.linkTransactionWithReceiver(payload).then(res => {
            if (this.debugConsole) console.log("link transaction with receiver with selected Self recipient result", res)

            let payload = {};
            payload.txReference = res.data && res.data.tx_ref_num;
            payload.receiverKey = receiverKey;

            //get the review transaction data
            this.getReviewTransaction(payload).then(res => {
              if (this.debugConsole) console.log("Get Review Transaction result with selected self recipient", res);

              this.$store.commit('UPDATE_STEPPER', 'review-info');

              this.$router.push('/transfer/review-details');
              this.getReceiversAfterSubmitNewBankDetails();
            }).catch(err => {
              if (this.debugConsole) console.log("Get Review Transaction error with selected self recipient", err);
            })

          }).catch(err => {
            if (this.debugConsole) console.log("Error link transaction with receiver", err.response);

          })

        }).catch(error => {
          if (this.debugConsole) console.log('Update recipient bank error in transfer recipient details (self) comp (new)', error.response);
        })
      }).catch(error => {
        // close loader if error occur
        this.$vs.loading.close('#recipient-wrapper > .con-vs-loading');
        if (this.debugConsole) console.log('Create recipient profile error in transfer recipient details (self) comp (new)', error.response);
      })
    },

    submitNewAnotherRecipientBankAccount() {
      if (!this.newRecipientFullName) {
        this.validationNewRecipientFullNameError = true;
      } else {
        this.validationNewRecipientFullNameError = false;
      }

      if (!this.newRecipientCountry) {
        this.validationNewRecipientCountryError = true;
      } else {
        this.validationNewRecipientCountryError = false;
      }

      // if (!this.newRecipientCity) {
      //     this.validationNewRecipientCityError = true;
      // } else {
      //     this.validationNewRecipientCityError = false;
      // }

      if (!this.newRecipientAddress) {
        this.validationNewRecipientAddressError = true;
      } else {
        this.validationNewRecipientAddressError = false;
      }

      if (!this.newRecipientPostalCode) {
        this.validationNewRecipientPostalCodeError = true;
      } else {
        this.validationNewRecipientPostalCodeError = false;
      }

      // if (!this.newRecipientState) {
      //     this.validationNewRecipientStateError = true;
      // } else {
      //     this.validationNewRecipientStateError = false;
      // }


      // if (!this.newRecipientEmail || this.newRecipientEmail && this.newRecipientEmail.indexOf('@') < 2) {
      //     this.validationNewRecipientEmailError = true;
      // } else {
      //     this.validationNewRecipientEmailError = false;
      // }

      if (!this.newRecipientBankAccountHolderName) {
        this.validationNewRecipientBankAccountHolderNameError = true;
      } else {
        this.validationNewRecipientBankAccountHolderNameError = false;
      }

      if (!this.newRecipientBankAccountNumber) {
        this.validationNewRecipientBankAccountNumberError = true;
      } else {
        this.validationNewRecipientBankAccountNumberError = false;
      }

      if (!this.newRecipientBankName) {
        this.validationNewRecipientBankNameError = true;
      } else {
        this.validationNewRecipientBankNameError = false;
      }

      if (!this.newRecipientBankSWIFTCode) {
        this.validationNewRecipientBankSWIFTCodeError = true;
      } else {
        this.validationNewRecipientBankSWIFTCodeError = false;

      }

      if (!this.newRecipientBankCountry) {
        this.validationNewRecipientBankCountryError = true;
      } else {
        this.validationNewRecipientBankCountryError = false;
      }

      if (!this.newRecipientBankAccountCurrency) {
        this.validationNewRecipientBankAccountCurrencyError = true;
      } else {
        this.validationNewRecipientBankAccountCurrencyError = false;
      }

      if (this.validationNewRecipientFullNameError || this.validationNewRecipientCountryError || this.validationNewRecipientAddressError ||
          this.validationNewRecipientPostalCodeError || this.validationNewRecipientBankAccountHolderNameError || this.validationRecipientBankAccountNumberError ||
          this.validationNewRecipientBankNameError || this.validationNewRecipientBankSWIFTCodeError || this.validationNewRecipientBankCountryError || this.validationNewRecipientBankAccountCurrencyError) {
        return;
      }

      // set loader
      this.$vs.loading({
          container: '#recipient-wrapper',
          scale: 0.8,
          color: 'success'
      });


      //send to API to create recipient
      let payload = {}
      payload.receiver_type = "another"
      payload.name = this.newRecipientFullName;
      payload.country = this.newRecipientCountry;
      payload.address_lines = this.newRecipientAddress;
      // payload.address_city = this.newRecipientCity;
      // payload.address_state = this.newRecipientState || "";
      payload.postal_code = this.newRecipientPostalCode;
      // payload.email = this.newRecipientEmail;

      if (this.debugConsole) console.log("Payload for sending to someone else (new)", payload);

      this.createRecipientProfile(payload).then(res => {
        if (this.debugConsole) console.log("Create Recipient Profile in transfer recipient details (another) comp (new)", res);

        let receiverKey = res.data && res.data.receiver_key;

        //get the receiver key to update the bank
        let payload = {}
        payload.receiverKey = receiverKey;
        payload.bank = {}
        payload.bank.acct_name = this.newRecipientBankAccountHolderName;
        payload.bank.acct_num = this.newRecipientBankAccountNumber;
        payload.bank.bank_name = this.newRecipientBankName;
        payload.bank.bank_swift = this.newRecipientBankSWIFTCode;
        payload.bank.bank_country = this.newRecipientBankCountry;
        payload.bank.acct_ccy = this.newRecipientBankAccountCurrency;


        this.updateRecipientBank(payload).then(res => {
          if (this.debugConsole) console.log("Update recipient bank result in transfer recipient details (another) comp (new)", res)
          // this.closeFormForAddAnotherRecipientBankDetails();
          this.getReceiversAfterSubmitNewBankDetails();

          //Link and call the review transaction
          let payload = {};
          payload.receiverKey = receiverKey;

          if (this.debugConsole) console.log("Link transaction for self bank details (new)");

          //Link receiver with transaction
          this.linkTransactionWithReceiver(payload).then(res => {
            if (this.debugConsole) console.log("link transaction with receiver with selected Self recipient result", res)

            let payload = {};
            payload.txReference = res.data && res.data.tx_ref_num;
            payload.receiverKey = receiverKey;

            //get the review transaction data
            this.getReviewTransaction(payload).then(res => {
              if (this.debugConsole) console.log("Get Review Transaction result with selected self recipient", res);

              this.$store.commit('UPDATE_STEPPER', 'review-info');

              this.$router.push('/transfer/review-details');
              this.getReceiversAfterSubmitNewBankDetails();
            }).catch(err => {
              if (this.debugConsole) console.log("Get Review Transaction error with selected self recipient", err);
            })

          }).catch(err => {
            if (this.debugConsole) console.log("Error link transaction with receiver", err.response);

          })

        }).catch(err => {
          if (this.debugConsole) console.log("Update new recipient bank error in transfer recipient details (another) comp", err);
        })
      }).catch(err => {
        // close loader if error occur
        this.$vs.loading.close('#recipient-wrapper > .con-vs-loading');
        if (this.debugConsole) console.log("create new Recipient profile error in transfer recipient details (another) comp", err);
        if (err.response.data.errors) {
          if (err.response.data.errors.find(el => el.message === 'invalid' && el.path === 'email')) {
            this.validationNewRecipientEmailError = true;
          }
        }
      })

    },


    getReceiversAfterSubmitNewBankDetails() {
      this.getReceivers().then(res => {
        if (this.debugConsole) console.log("Call get receivers after submit bank details")
      });
    },


    submitRecipientDetails() {
      // set loader
      this.$vs.loading({
          container: '#recipient-wrapper',
          scale: 0.8,
          color: 'success'
      });
      
      if (this.sendToMyself) {
        //for existing self bank details
        // if (this.selfBankDetails.length > 0) {
        //     if(this.debugConsole)   console.log("Self Bank details exist");
        //     if(this.debugConsole)   console.log("Selected Recipient when submit", this.selectedRecipient);
        //
        //     if (this.selectedRecipient === null) {
        //         if(this.debugConsole)    console.log("Recipient selected", this.selectedRecipient)
        //         this.validationErrorRecipient = true;
        //     } else {
        //         if(this.debugConsole)   console.log("Recipient selected", this.selectedRecipient)
        //         this.validationErrorRecipient = false;
        //
        //         let receiverKey = this.selectedRecipient && this.selectedRecipient.receiver_key;
        //
        //         //only need to link and call the review transaction
        //         let payload = {};
        //         payload.receiverKey = receiverKey
        //
        //         //Link receiver with transaction
        //         this.linkTransactionWithReceiver(payload).then(res => {
        //             if(this.debugConsole)    console.log("link transaction with receiver with selected Self recipient result", res)
        //
        //             let payload = {};
        //             payload.txReference = res.data && res.data.tx_ref_num;
        //             payload.receiverKey = receiverKey;
        //
        //             //get the review transaction data
        //             this.getReviewTransaction(payload).then(res => {
        //                 if(this.debugConsole)    console.log("Get Review Transaction result with selected self recipient", res);
        //
        //                 this.$store.commit('UPDATE_STEPPER', 'review-info');
        //
        //                 this.$router.push('/transfer/review-details');
        //                 this.getReceiversAfterSubmitNewBankDetails();
        //             }).catch(err => {
        //                 if(this.debugConsole)  console.log("Get Review Transaction error with selected self recipient", err);
        //             })
        //
        //         }).catch(err => {
        //             if(this.debugConsole)  console.log("Error link transaction with receiver", err.response);
        //
        //         })
        //
        //
        //     }
        //
        // }
        //for non-existing self bank details
        // else
        // {
        if (this.debugConsole) console.log("Self Bank Details does not exist");
        if (!this.selfBankAccountHolderName) {
          this.validationSelfBankAccountHolderNameError = true;
        } else {
          this.validationSelfBankAccountHolderNameError = false;
        }

        if (!this.selfBankAccountNumber) {
          this.validationSelfBankAccountNumberError = true;
        } else {
          this.validationSelfBankAccountNumberError = false;
        }

        if (!this.selfBankName) {
          this.validationSelfBankNameError = true;
        } else {
          this.validationSelfBankNameError = false;
        }

        if (!this.selfBankSWIFTCode) {
          this.validationSelfBankSWIFTCodeError = true;
        } else {
          this.validationSelfBankSWIFTCodeError = false;
        }

        if (!this.selfBankCountry) {
          this.validationSelfBankCountryError = true;
        } else {
          this.validationSelfBankCountryError = false;
        }

        if (!this.selfBankAccountCurrency) {
          this.validationSelfBankAccountCurrencyError = true;
        } else {
          this.validationSelfBankAccountCurrencyError = false;
        }

        if (this.validationSelfBankAccountHolderNameError ||
            this.validationSelfBankAccountNumberError ||
            this.validationSelfBankNameError ||
            this.validationSelfBankSWIFTCodeError || this.validationSelfBankCountryError || this.validationSelfBankAccountCurrencyError) {
          return;
        }

        //send to API to create recipient
        let payload = {}
        payload.receiver_type = "self"
        payload.name = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderFirstAndMiddleName + " " + this.userDetailsGetter.profile.senderLastName);
        payload.country = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderCountry;
        payload.address_lines = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderAddress;
        payload.address_city = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderCity;
        // payload.address_state = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderProvince;
        payload.postal_code = this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderPostalCode;
        payload.email = this.userDetailsGetter.email;

        if (this.debugConsole) console.log("Payload for sending to myself", payload);

        this.createRecipientProfile(payload).then(res => {
          if (this.debugConsole) console.log("create recipient profile result in transfer recipient details (self) comp", res);

          let receiverKey = res.data && res.data.receiver_key;

          //get the receiver key to update the bank
          let payload = {}
          payload.receiverKey = receiverKey;
          payload.bank = {}
          payload.bank.acct_name = this.selfBankAccountHolderName;
          payload.bank.acct_num = this.selfBankAccountNumber;
          payload.bank.bank_name = this.selfBankName;
          payload.bank.bank_swift = this.selfBankSWIFTCode;
          payload.bank.bank_country = this.selfBankCountry;
          payload.bank.acct_ccy = this.selfBankAccountCurrency;

          if (this.debugConsole) console.log("Update Receipient bank payload", payload);

          this.updateRecipientBank(payload).then(res => {
            if (this.debugConsole) console.log("Update recipient bank result in transfer recipient details (self) comp", res)

            //Link and call the review transaction
            let payload = {};
            payload.receiverKey = receiverKey;

            if (this.debugConsole) console.log("Link transaction for self bank details (new)");

            //Link receiver with transaction
            this.linkTransactionWithReceiver(payload).then(res => {
              if (this.debugConsole) console.log("link transaction with receiver with selected Self recipient result", res)

              let payload = {};
              payload.txReference = res.data && res.data.tx_ref_num;
              payload.receiverKey = receiverKey;

              //get the review transaction data
              this.getReviewTransaction(payload).then(res => {
                if (this.debugConsole) console.log("Get Review Transaction result with selected self recipient", res);

                this.$store.commit('UPDATE_STEPPER', 'review-info');

                this.$router.push('/transfer/review-details');
                this.getReceiversAfterSubmitNewBankDetails();
              }).catch(err => {
                if (this.debugConsole) console.log("Get Review Transaction error with selected self recipient", err);
              })

            }).catch(err => {
              if (this.debugConsole) console.log("Error link transaction with receiver", err.response);

            })


          }).catch(error => {
            if (this.debugConsole) console.log('Update recipient bank error in transfer recipient details (self) comp', error.response);
          })


        }).catch(error => {
          if (this.debugConsole) console.log('Create recipient profile error in transfer recipient details (self) comp', error.response);

        })
      }


      // }
      else {
        //for existing another recipient bank details
        // if (this.anotherBankDetails.length > 0) {
        //     if(this.debugConsole)  console.log("Another Bank Details exist");
        //     if(this.debugConsole)  console.log("Selected Another Recipient when submit", this.selectedRecipient)
        //
        //     if (this.selectedRecipient === null) {
        //         if(this.debugConsole) console.log("Recipient selected", this.selectedRecipient)
        //         this.validationErrorRecipient = true;
        //     } else {
        //         if(this.debugConsole)  console.log("Recipient selected", this.selectedRecipient)
        //         this.validationErrorRecipient = false;
        //
        //         let receiverKey = this.selectedRecipient && this.selectedRecipient.receiver_key;
        //
        //         //only need to link and call the review transaction
        //         let payload = {};
        //         payload.receiverKey = receiverKey
        //
        //         //Link receiver with transaction
        //         this.linkTransactionWithReceiver(payload).then(res => {
        //             if(this.debugConsole)   console.log("link transaction with receiver with selected another recipient result", res)
        //
        //             let payload = {};
        //             payload.txReference = res.data && res.data.tx_ref_num;
        //             payload.receiverKey = receiverKey;
        //
        //             //get the review transaction data
        //             this.getReviewTransaction(payload).then(res => {
        //                 if(this.debugConsole)   console.log("Get Review Transaction result with selected another recipient", res);
        //
        //                 this.$store.commit('UPDATE_STEPPER', 'review-info');
        //
        //                 this.$router.push('/transfer/review-details');
        //                 this.getReceiversAfterSubmitNewBankDetails();
        //             }).catch(err => {
        //                 if(this.debugConsole)   console.log("Get Review Transaction error with selected another recipient", err);
        //             })
        //
        //         }).catch(err => {
        //             if(this.debugConsole)   console.log("Error link transaction with receiver with selected another recipient", err.response);
        //         })
        //     }
        // }
        //for non-existing another recipient bank details
        // else
        // {
        if (!this.recipientFullName) {
          this.validationRecipientFullNameError = true;
        } else {
          this.validationRecipientFullNameError = false;
        }

        if (!this.recipientCountry) {
          this.validationRecipientCountryError = true;
        } else {
          this.validationRecipientCountryError = false;
        }

        // if (!this.recipientCity) {
        //     this.validationRecipientCityError = true;
        // } else {
        //     this.validationRecipientCityError = false;
        // }

        if (!this.recipientAddress) {
          this.validationRecipientAddressError = true;
        } else {
          this.validationRecipientAddressError = false;
        }

        if (!this.recipientPostalCode) {
          this.validationRecipientPostalCodeError = true;
        } else {
          this.validationRecipientPostalCodeError = false;
        }

        // if (!this.recipientState) {
        //     this.validationRecipientStateError = true;
        // } else {
        //     this.validationRecipientStateError = false;
        // }


        // if (!this.recipientEmail || this.recipientEmail && this.recipientEmail.indexOf('@') < 2) {
        //     this.validationRecipientEmailError = true;
        // } else {
        //     this.validationRecipientEmailError = false;
        // }

        if (!this.recipientBankAccountHolderName) {
          this.validationRecipientBankAccountHolderNameError = true;
        } else {
          this.validationRecipientBankAccountHolderNameError = false;
        }

        if (!this.recipientBankAccountNumber) {
          this.validationRecipientBankAccountNumberError = true;
        } else {
          this.validationRecipientBankAccountNumberError = false;
        }

        if (!this.recipientBankName) {
          this.validationRecipientBankNameError = true;
        } else {
          this.validationRecipientBankNameError = false;
        }

        if (!this.recipientBankSWIFTCode) {
          this.validationRecipientBankSWIFTCodeError = true;
        } else {
          this.validationRecipientBankSWIFTCodeError = false;

        }

        if (!this.recipientBankCountry) {
          this.validationRecipientBankCountryError = true;
        } else {
          this.validationRecipientBankCountryError = false;
        }

        if (!this.recipientBankAccountCurrency) {
          this.validationRecipientBankAccountCurrencyError = true;
        } else {
          this.validationRecipientBankAccountCurrencyError = false;
        }

        if (this.validationRecipientFullNameError || this.validationRecipientCountryError || this.validationRecipientAddressError || this.validationRecipientPostalCodeError || this.validationRecipientBankAccountHolderNameError || this.validationRecipientBankAccountNumberError ||
            this.validationRecipientBankNameError || this.validationRecipientBankSWIFTCodeError || this.validationRecipientBankCountryError || this.validationRecipientBankAccountCurrencyError) {
          return;
        }

        //send to API to create recipient
        let payload = {}
        payload.receiver_type = "another"
        payload.name = this.recipientFullName;
        payload.country = this.recipientCountry;
        payload.address_lines = this.recipientAddress
        // payload.address_city = this.recipientCity;
        // payload.address_state = this.recipientState || "";
        payload.postal_code = this.recipientPostalCode;
        // payload.email = this.recipientEmail;

        if (this.debugConsole) console.log("Payload for sending to someone else", payload);

        this.createRecipientProfile(payload).then(res => {
          if (this.debugConsole) console.log("Create Recipient Profile in transfer recipient details (another) comp", res);

          let receiverKey = res.data && res.data.receiver_key;

          //get the receiver key to update the bank
          let payload = {}
          payload.receiverKey = receiverKey;
          payload.bank = {}
          payload.bank.acct_name = this.recipientBankAccountHolderName;
          payload.bank.acct_num = this.recipientBankAccountNumber;
          payload.bank.bank_name = this.recipientBankName;
          payload.bank.bank_swift = this.recipientBankSWIFTCode;
          payload.bank.bank_country = this.recipientBankCountry;
          payload.bank.acct_ccy = this.recipientBankAccountCurrency;


          this.updateRecipientBank(payload).then(res => {
            if (this.debugConsole) console.log("Update recipient bank result in transfer recipient details (another) comp", res)


            //Link and call the review transaction
            let payload = {};
            payload.receiverKey = receiverKey;

            if (this.debugConsole) console.log("Link transaction for another bank details (new)");

            //Link receiver with transaction
            this.linkTransactionWithReceiver(payload).then(res => {
              if (this.debugConsole) console.log("link transaction with receiver with selected Self recipient result", res)

              let payload = {};
              payload.txReference = res.data && res.data.tx_ref_num;
              payload.receiverKey = receiverKey;

              //get the review transaction data
              this.getReviewTransaction(payload).then(res => {
                if (this.debugConsole) console.log("Get Review Transaction result with selected self recipient", res);

                this.$store.commit('UPDATE_STEPPER', 'review-info');

                this.$router.push('/transfer/review-details');
                this.getReceiversAfterSubmitNewBankDetails();
              }).catch(err => {
                if (this.debugConsole) console.log("Get Review Transaction error with selected self recipient", err);
              })

            }).catch(err => {
              if (this.debugConsole) console.log("Error link transaction with receiver", err.response);
            })

          }).catch(err => {
            if (this.debugConsole) console.log("Update recipient bank error in transfer recipient details (another) comp", err);
          })
        }).catch(err => {
          if (this.debugConsole) console.log("create Recipient profile error in transfer recipient details (another) comp", err);
          if (err.response.data.errors) {
            if (err.response.data.errors.find(el => el.message === 'invalid' && el.path === 'email')) {
              this.validationRecipientEmailError = true;
            }
          }
        })
        // }

      }

      // close loader if error occur
      this.$vs.loading.close('#recipient-wrapper > .con-vs-loading');
    }
  },

}
</script>
